import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { categoryWiseSubCategoryFetching } from "../../features/products/categoryWiseSubCategorySlice";
const VendorUploadProduct = () => {
  const dispatch = useDispatch(null);
  const { userAndToken: vendorData } = useSelector((state) => state.auth);
  const { items: categoryData } = useSelector((state) => state.category);
  const { items: subcategory } = useSelector(
    (state) => state.categoryWiseSubCategory
  );
  const { items: brandData } = useSelector((state) => state.brand);

  const [pictures, setPictures] = useState([]);
  const [image, setImage] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleImage = (e) => {
    const selectedImages = Array.from(e.target.files);
    setPictures(selectedImages);
  };

  const handleSingleImage = (e) => {
    setImage({ image: e.target.files[0] });
  };

  const [productData, setProductData] = useState({
    id: vendorData?.user?.id,
    sell_name: null,
    brand_id: null,
    price: null,
    details: null,
    vendor_product: 2,
    category_id: null,
    subcategory_id: null,
    current_stock: 1,
    unit: "",
    min_qty: 1,
  });

  useEffect(() => {
    dispatch(categoryWiseSubCategoryFetching(productData?.category_id));
  }, [dispatch, productData?.category_id]);

  useEffect(() => {
    dispatch(categoryWiseSubCategoryFetching(productData?.category_id));
  }, [dispatch, productData?.category_id]);

  const handleForm = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);

      const formData = new FormData();

      formData.append("vendor_id", productData.id);
      pictures.forEach((picture) => {
        formData.append("icon[]", picture);
      });

      formData.append("photos", image?.image);
      formData.append("name", productData.sell_name);
      formData.append("unit_price", productData.price);
      formData.append("description", productData.details);
      formData.append("vendor_product", productData.vendor_product);
      formData.append("category_id", productData.category_id);
      formData.append("brand_id", productData.brand_id);
      formData.append("current_stock", productData.current_stock);
      formData.append("unit", productData.unit);
      formData.append("min_qty", productData.min_qty);
      formData.append("subcategory_id", productData.subcategory_id);

      try {
        await axios.post(
          `${process.env.REACT_APP_URL}/api/product-add`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${vendorData.access_token}`,
            },
          }
        );

        setProductData({
          id: vendorData?.user?.id,
          sell_name: "",
          brand_id: null,
          price: "",
          details: "",
          vendor_product: 2,
          category_id: null,
          subcategory_id: null,
          current_stock: 1,
          unit: "",
          min_qty: 1,
        });

        setLoading(false);
        setPictures([]);
        setImage([]);

        alert("Product uploaded successfully!");
      } catch (error) {
        console.error("There was an error uploading the product:", error);
        alert("Failed to upload product. Please try again.");
      }
    },
    [
      productData,
      vendorData?.user?.id,
      vendorData.access_token,
      pictures,
      image,
    ]
  );

  return (
    <div className="px-3 py-3 ">
      <h3 className="text-2xl mb-6">Products Upload</h3>
      <form
        onSubmit={handleForm}
        className="grid grid-cols-1 md:grid-cols-2 gap-16"
      >
        <div className="form-left flex flex-col gap-4">
          <div className="form-control flex w-full flex-col gap-2 ">
            <select
              required
              onChange={(e) =>
                setProductData({ ...productData, category_id: e.target.value })
              }
              className="px-3 py-2 rounded-md outline-none border focus:border-[#6D0C48] duration-300"
            >
              <option value="">Select Category</option>
              {categoryData?.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-control flex w-full flex-col gap-2 ">
            <select
              required
              onChange={(e) =>
                setProductData({
                  ...productData,
                  subcategory_id: e.target.value,
                })
              }
              className="px-3 py-2 rounded-md outline-none border focus:border-[#6D0C48] duration-300"
            >
              <option value="">Select Subcategory</option>
              {subcategory?.subcategory?.map((subcategory) => (
                <option key={subcategory.id} value={subcategory.id}>
                  {subcategory.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-control flex w-full flex-col gap-2 ">
            <select
              required
              onChange={(e) =>
                setProductData({
                  ...productData,
                  brand_id: e.target.value,
                })
              }
              className="px-3 py-2 rounded-md outline-none border focus:border-[#6D0C48] duration-300"
            >
              <option value="">Select Brand</option>
              {brandData?.map((brand) => (
                <option key={brand.id} value={brand.id}>
                  {brand.name}
                </option>
              ))}
            </select>
          </div>
          <>
            <div className="form-control flex w-full flex-col gap-2 ">
              <label>
                Product Name &nbsp;
                <span className="text-red-600 font-semibold">*</span>
              </label>
              <input
                type="text"
                onChange={(e) =>
                  setProductData({
                    ...productData,
                    sell_name: e.target.value,
                  })
                }
                value={productData.sell_name}
                placeholder="Enter Your Product Name"
                className="py-2 px-2 border border-gray-300 focus:outline-none rounded-md bg-transparent"
                required
              />
            </div>

            <div className="form-control flex w-full flex-col gap-2">
              <label>
                Details &nbsp;
                <span className="text-red-600 font-semibold">*</span>
              </label>
              <textarea
                value={productData.details}
                onChange={(e) =>
                  setProductData({
                    ...productData,
                    details: e.target.value,
                  })
                }
                placeholder="Enter Your Product Details"
                className="py-2 px-2 border border-gray-300 focus:outline-none rounded-md bg-transparent"
                required
              />
            </div>
            <div className="form-control flex w-full flex-col gap-2 ">
              <label>
                Quantity &nbsp;
                <span className="text-red-600 font-semibold">*</span>
              </label>
              <input
                type="number"
                onChange={(e) =>
                  setProductData({
                    ...productData,
                    current_stock: e.target.value,
                  })
                }
                value={productData.current_stock}
                className="py-2 px-2 border border-gray-300 focus:outline-none rounded-md bg-transparent"
                required
              />
            </div>
          </>
        </div>
        <div className="form-right">
          <div className="form-control flex w-full flex-col gap-2">
            <label>
              Min Qty &nbsp;
              <span className="text-red-600 font-semibold">*</span>
            </label>
            <input
              type="number"
              onChange={(e) =>
                setProductData({
                  ...productData,
                  min_qty: e.target.value,
                })
              }
              value={productData.min_qty}
              className="py-2 px-2 border border-gray-300 focus:outline-none rounded-md bg-transparent"
              required
            />
          </div>
          <div className="form-control flex w-full flex-col gap-2">
            <label>
              Price &nbsp;
              <span className="text-red-600 font-semibold">*</span>
            </label>
            <input
              type="number"
              value={productData.price}
              onChange={(e) =>
                setProductData({ ...productData, price: e.target.value })
              }
              className="py-2 px-2 border border-gray-300 focus:outline-none rounded-md bg-transparent"
              placeholder="Enter Your Product Price"
              required
            />
          </div>
          <div className="form-control flex w-full flex-col gap-2">
            <label>
              Unit &nbsp;
              <span className="text-red-600 font-semibold">*</span>
            </label>
            <input
              type="text"
              value={productData.unit}
              onChange={(e) =>
                setProductData({ ...productData, unit: e.target.value })
              }
              placeholder="(e.g. Kg,Pcs,Pair etc)"
              className="py-2 px-2 border border-gray-300 focus:outline-none rounded-md bg-transparent"
              required
            />
          </div>
          <div className="form-control flex w-full flex-col gap-2 my-10">
            <label>
              Thumbnail Image &nbsp;
              <span className="text-red-600 font-semibold">*</span>
            </label>
            <input
              type="file"
              accept="image/*"
              onChange={handleSingleImage}
              required
            />

            {/* <div className="flex flex-wrap gap-2">
                  <div>
                    <div
                      onClick={openFileExplorers}
                      className="h-28 w-32 border-2 cursor-pointer rounded-xl flex flex-col items-center justify-center gap-2"
                    >
                      <div className="add_image w-[38px] h-[36px] overflow-hidden">
                        <img
                          className="w-full h-full"
                          src="../../../blur_image.jpeg"
                          alt=""
                        />
                      </div>
                      <span className="text-black/30 font-semibold">
                        Add a photo
                      </span>
                    </div>
                    <span className="text-sm mt-3 font-semibold">
                      {images && images.name}
                    </span>
                  </div>
                </div> */}
          </div>

          <div className="form-control flex w-full flex-col gap-2 mb-10">
            <label>
              Image &nbsp;
              <span className="text-red-600 font-semibold">*</span>
            </label>
            <input
              type="file"
              // ref={inputRef}
              accept="image/*"
              multiple
              onChange={handleImage}
              required
            />

            {/* <div className="flex flex-col">
                  <div className="flex flex-wrap gap-2">
                    {[...Array(4)].map((_, index) => (
                      <div key={index}>
                        <div
                          onClick={openFileExplorer}
                          className="h-28 w-32 border-2 cursor-pointer rounded-xl flex flex-col items-center justify-center gap-2"
                        >
                          <div className="add_image w-[38px] h-[36px] overflow-hidden">
                            <img
                              className="w-full h-full"
                              src="../../../blur_image.jpeg"
                              alt=""
                            />
                          </div>
                          <span className="text-black/30 font-semibold">
                            Add a Photo
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>

                  <span className="mt-5 font-semibold">
                    {picture &&
                      picture.map((pic, index) => (
                        <span
                          key={index}
                          className="flex text-sm gap-1 flex-col"
                        >
                          {pic.name}
                        </span>
                      ))}
                  </span>
                </div> */}
          </div>
          <hr className="text-[#E5E5E5]" />

          <button
            className="bg-[#6D0C48] text-white w-full h-12 rounded mt-10"
            disabled={loading}
            type="submit"
          >
            {loading ? "Waiting for submitting......." : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default VendorUploadProduct;
