import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Parser } from "html-to-react";

import { singleFooterFetching } from "../../features/pages/singleFooterPageSlice";
import Loader from "../../components/Loader";

const Pages = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const htmlParser = new Parser();

  const { items: singleFooterPage, loading } = useSelector(
    (state) => state.singleFooterPage
  );

  useEffect(() => {
    dispatch(singleFooterFetching(slug));
  }, [dispatch, slug]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="container mx-auto min-h-[calc(100vh-17rem)] bg-[#F2F3F8] px-4 py-10">
          <h2 className="text-2xl font-semibold text-center">
            {singleFooterPage.title}
          </h2>
          <p className="mt-10 ">
            {htmlParser.parse(singleFooterPage.descriptions)}
          </p>
        </div>
      )}
    </>
  );
};

export default Pages;
