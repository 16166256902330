import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  items: {},
  loading: false,
  error: null,
};

//data fetching

export const singleFooterFetching = createAsyncThunk(
  "singleFooter/singleFooterFetching",
  async (slug) => {
    const res = await axios.get(
      `${process.env.REACT_APP_URL}/api-pages/${slug}`
    );
    return res.data;
  }
);

export const singleFooterSlice = createSlice({
  name: "singleFooter",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(singleFooterFetching.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(singleFooterFetching.fulfilled, (state, action) => {
      state.loading = false;
      state.items = action.payload;
    });
    builder.addCase(singleFooterFetching.rejected, (state, action) => {
      // state.status = "Failed";
      state.error = action.error.message;
    });
  },
});

export default singleFooterSlice.reducer;
