// import { useCallback, useState } from "react";
import FormInput from "../components/FormInput";
import { axiosPost } from "../lib/axiosPost";
// import { login } from "../features/auth/authSlice";
// import { toast } from "react-toastify";
// import { useDispatch } from "react-redux";
// import { Link, useNavigate } from "react-router-dom";
// import { MoonLoader } from "react-spinners";

// const Login = () => {
//   const [formData, setFormData] = useState({
//     email: "",
//     password: "",
//     role: 1,
//   });
//   // const { items: logo } = useSelector((state) => state.logo);
//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   const [eye, setEye] = useState(false);
//   const [type, setType] = useState("password");
//   const [isLoading, setIsLoading] = useState(false);
//   const [error, setError] = useState(null);

//   const handleInput = (e, name) => {
//     setFormData((prev) => ({ ...prev, [name]: e.target.value }));
//   };

//   const handleEye = () => {
//     setEye((prev) => !prev);
//     if (type === "password") {
//       setType("text");
//     } else {
//       setType("password");
//     }
//   };

//   const handleSubmit = useCallback(
//     async (e) => {
//       e.preventDefault();
//       setIsLoading(true);
//       const data = await axiosPost("/api/user/login", formData);

//       if (data?.status === 401) {
//         setError(data.error);
//         setIsLoading(false);
//       } else {
//         setIsLoading(false);
//         setFormData({
//           email: "",
//           password: "",
//         });
//         dispatch(login(data));
//         toast.success("Login successfull.");
//         navigate("/");
//       }
//     },
//     [formData, dispatch, navigate]
//   );
//   return (
//     <div className="flex justify-center items-center min-h-[calc(100vh-12rem)] w-full  px-2 md:px-0 ">
//       <form
//         onSubmit={handleSubmit}
//         className="bg-white shadow-lg rounded-md p-4 max-w-[25rem] w-[24rem] h-full flex flex-col justify-center items-center"
//       >
//         <div className="form-header flex flex-col items-center justify-center">
//           <h2 className="text-3xl font-semibold text-[#000]">Welcome</h2>
//           <span className="text-xl font-medium italic">to</span>
//           <p className="text-2xl font-semibold text-[#6D0C48]">Bazar Map</p>
//           {/* <div className="w-[5rem] h-[5rem] overflow-hidden">
//             <img
//               src={`${process.env.REACT_APP_URL}/backend/assets/jpg/${logo?.avatar}`}
//               className="w-full h-full "
//               alt=""
//             />
//           </div> */}
//         </div>
//         <FormInput
//           label={"Email"}
//           type={"email"}
//           value={formData.email}
//           name={"email"}
//           placeholder={"Enter Your Email"}
//           onChange={(e) => handleInput(e, "email")}
//         />
//         <FormInput
//           label={"Password"}
//           type={type}
//           value={formData.password}
//           name={"password"}
//           placeholder={"Enter Your Password"}
//           onChange={(e) => handleInput(e, "password")}
//           onClick={handleEye}
//           eye={eye}
//         />
//         {error && <p className="text-red-500">{error}</p>}
//         <button className="bg-[#6D0C48] hover:bg-[#6d0c48ee] duration-300 w-full text-white py-2 mt-2 rounded-full">
//           <span className="flex justify-center items-center gap-2">
//             {isLoading && (
//               <MoonLoader
//                 color="rgb(255, 255, 255)"
//                 loading={isLoading}
//                 size={15}
//                 aria-label="Loading Spinner"
//                 data-testid="loader"
//               />
//             )}
//             Login
//           </span>
//         </button>
//         {/* <Link to={"/"} className="mt-2 text-gray-400 text-lg">
//           Forgot Password?
//         </Link> */}
//         <span className="text-gray-400 mt-2">
//           Don't have an account?{" "}
//           <Link className="text-[#6D0C48]" to={"/register"}>
//             SignUp
//           </Link>
//         </span>
//       </form>
//     </div>
//   );
// };

// export default Login;

import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { MoonLoader } from "react-spinners";
// import { axiosPost } from "../../libs/axiosPost";
import { login } from "../features/auth/authSlice";
import { toast } from "react-toastify";

const VendorLogin = () => {
  const option = [
    {
      id: 1,
      name: "Customer",
      role: 1,
    },
    {
      id: 2,
      name: "Become A Seller",
      role: 2,
    },
  ];

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    role: 1,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [eye, setEye] = useState(false);
  const [type, setType] = useState("password");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleInput = (e, name) => {
    setFormData((prev) => ({ ...prev, [name]: e.target.value }));
  };

  const handleEye = () => {
    setEye((prev) => !prev);
    setType((prevType) => (prevType === "password" ? "text" : "password"));
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setIsLoading(true);
      const data = await axiosPost("/api/user/login", formData);

      if (data?.status === 401) {
        setError(data?.error);
        setIsLoading(false);
      } else {
        if (data === undefined) {
          setError("Incorrect Email or Password!");
          setIsLoading(false);
          return;
        } else {
          setFormData({
            email: "",
            password: "",
            role: 1, // Reset to vendor role by default
          });
          dispatch(login(data));
          toast.success("Login successful.");
          navigate("/");
        }
      }
    },
    [formData, dispatch, navigate]
  );

  const handleRoleChange = (role) => {
    setFormData((prev) => ({ ...prev, role }));
  };

  return (
    <div className="flex justify-center items-center min-h-[calc(100vh-20rem)] px-2 md:px-0">
      <form
        onSubmit={handleSubmit}
        className="bg-white shadow-lg rounded-md p-4 max-w-[25rem] w-full md:w-[24rem] flex flex-col justify-center items-center"
      >
        <div className="form-header flex flex-col items-center justify-center mb-4">
          <h2 className="text-3xl font-semibold text-[#000]">Welcome</h2>
          <span className="text-xl font-medium italic">to</span>
          <p className="text-2xl font-semibold text-[#6D0C48]">Bazar Map</p>
        </div>
        <FormInput
          label={"Email"}
          type={"tel"}
          value={formData.email}
          name={"email"}
          pattern="[0-9]{11}"
          placeholder={"Enter Your Email"}
          onChange={(e) => handleInput(e, "email")}
        />
        <FormInput
          label={"Password"}
          type={type}
          value={formData.password}
          name={"password"}
          placeholder={"Enter Your Password"}
          onChange={(e) => handleInput(e, "password")}
          onClick={handleEye}
          eye={eye}
        />

        <div className="my-5 w-full">
          <div className="form-control flex justify-center gap-2">
            {option?.map((data) => (
              <button
                key={data?.id}
                type="button"
                onClick={() => handleRoleChange(data.role)}
                className={`border py-2 px-4 capitalize rounded-md ${
                  formData.role === data.role
                    ? "bg-[#6D0C48] text-white"
                    : "bg-white text-gray-700"
                }`}
              >
                {data?.name}
              </button>
            ))}
          </div>
        </div>
        {error && <p className="text-red-500">{error}</p>}
        <button className="bg-[#6D0C48] hover:bg-[#6D0C48] duration-300 w-full text-white py-2 mt-2 rounded-full">
          <span className="flex justify-center items-center gap-2">
            {isLoading && (
              <MoonLoader
                color="rgb(109, 12, 72)"
                loading={isLoading}
                size={15}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            )}
            Login
          </span>
        </button>
        <span className="text-gray-400 mt-2">
          Do not have an account?
          <Link className="text-[#6D0C48]" to={"/register"}>
            SignUp
          </Link>
        </span>
      </form>
    </div>
  );
};

export default VendorLogin;
