import { combineReducers, configureStore } from "@reduxjs/toolkit";

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

import storage from "redux-persist/lib/storage";
import storageSession from "redux-persist/lib/storage/session";

import productReducer, {
  productFetching,
} from "../features/products/productSlice";
import cartReducer from "../features/products/cartSlice";
import categoryReducer, {
  categoryFetching,
} from "../features/products/categorySlice";
import bestSellingReducer, {
  bestSellingFetching,
} from "../features/products/bestSellingSlice";

import bannerReducer, {
  bannerFetching,
} from "../features/banner/sliderBannerSlice";

import homeBannerReducer, {
  homeBannerFetching,
} from "../features/banner/homeBannerSlice";

import subCategoryReducer, {
  subCategoryFetching,
} from "../features/category/subCategorySlice";
import logoReducer, { logoFatching } from "../features/logo/logoSlice";

import thumbImageReducer, {
  thumbImageFetching,
} from "../features/products/thambImageSlice";
import singleProductReducer, {
  singleProductFetching,
} from "../features/products/signleProductSlice";
import singleCategoryReducer, {
  singleCategoryFetching,
} from "../features/category/singleCategorySlice";
import shippingChargeReducer, {
  shippingChargeFetching,
} from "../features/shipping/shipingCharge";

import landingPageSlice, {
  landingPageFetching,
} from "../features/landingPage/landingPageSlice";
import singleLandingPageSlice from "../features/landingPage/singleLandingPageSlice";
import featuredReducer, {
  featuredFetching,
} from "../features/products/featuredSlice";
import newArrivalReducer, {
  newArrivalFetching,
} from "../features/products/newArrivalSlice";
import trendingReducer, {
  trendingFetching,
} from "../features/products/trendingSlice";
import specificAttributeReducer from "../features/products/specificAttributeSlice";
import authReducer from "../features/auth/authSlice";
import specificColorAttributeReducer from "../features/products/specificColorAttributeSlice";
import infoReducer, { infoFatching } from "../features/pages/userInfoSlice";
import categoryWiseSubCategoryReducer from "../features/products/categoryWiseSubCategorySlice";
import brandSlice, { brandsFetching } from "../features/products/brandSlice";
import vendorOrderSlice from "../features/products/vendorOrderSlice";
import vendorProductSlice from "../features/products/vendorProductSlice";
import singleEditProductSlice from "../features/products/singleProductEditSlice";
import singleVendorSlice from "../features/products/singleVendorSlice";
import footerPagesSlice, {
  footerPagesFetching,
} from "../features/pages/footerPageSlice";
import singleFooterPageSlice from "../features/pages/singleFooterPageSlice";
const rootPersistConfig = { key: "root", storage, whitelist: ["cart", "auth"] };
const authPersistConfig = { key: "user", storage: storageSession };

const rootReducer = combineReducers({
  //reducer in here
  products: productReducer,
  cart: cartReducer,
  category: categoryReducer,
  bestSell: bestSellingReducer,
  logo: logoReducer,

  banners: bannerReducer,
  homeBanner: homeBannerReducer,
  subCategor: subCategoryReducer,
  thumbImage: thumbImageReducer,
  landingPage: landingPageSlice,
  singleLandingPage: singleLandingPageSlice,
  singleProduct: singleProductReducer,

  shippingCharge: shippingChargeReducer,
  categoryProduct: singleCategoryReducer,
  featuredProduct: featuredReducer,
  newArrivalProduct: newArrivalReducer,
  trendingProduct: trendingReducer,
  attribute: specificAttributeReducer,
  colorAttribute: specificColorAttributeReducer,
  auth: persistReducer(authPersistConfig, authReducer),
  information: infoReducer,
  categoryWiseSubCategory: categoryWiseSubCategoryReducer,
  brand: brandSlice,
  vendorOrder: vendorOrderSlice,
  vendorProduct: vendorProductSlice,
  singleEditProduct: singleEditProductSlice,
  vendorSingleInfo: singleVendorSlice,
  footerPages: footerPagesSlice,
  singleFooterPage: singleFooterPageSlice,
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

store.dispatch(productFetching());
store.dispatch(categoryFetching());
store.dispatch(singleProductFetching());
store.dispatch(singleCategoryFetching());
store.dispatch(shippingChargeFetching());
store.dispatch(bestSellingFetching());
// store.dispatch(footerDetails());
store.dispatch(landingPageFetching());
store.dispatch(bannerFetching());
store.dispatch(thumbImageFetching());
store.dispatch(homeBannerFetching());
store.dispatch(subCategoryFetching());
store.dispatch(logoFatching());
store.dispatch(featuredFetching());
store.dispatch(newArrivalFetching());
store.dispatch(trendingFetching());
store.dispatch(infoFatching());
store.dispatch(brandsFetching());
store.dispatch(footerPagesFetching());
