import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";

const initialState = {
  items: [],
  status: null,
  loading: false,
};

export const singleVendorFatching = createAsyncThunk(
  "singleVendor/singleVendorFetching",
  async (id) => {
    const res = await axios.get(
      `${process.env.REACT_APP_URL}/api/user/info?id=${id}`
    );
    return res.data;
  }
);

export const singleVendorSlice = createSlice({
  name: "singleVendor",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(singleVendorFatching.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(singleVendorFatching.fulfilled, (state, action) => {
      state.loading = false;
      state.items = action.payload;
    });
    builder.addCase(singleVendorFatching.rejected, (state) => {
      state.loading = false;
      state.status = "Something Went Wrong";
    });
  },
});

export default singleVendorSlice.reducer;
