import React, { useState } from "react";
import axios from "axios";

const TrackOrder = () => {
  const [trackingCode, setTrackingCode] = useState("");
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleTrackOrder = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api-trackorder/${trackingCode}`
      );
      if (response.data.length > 0) {
        setOrderDetails(response.data[0]);
      } else {
        setOrderDetails(null);
      }
    } catch (error) {
      setError("Failed to fetch order details. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg my-5">
      <h2 className="text-2xl font-semibold mb-6 text-center">
        Track Your Order
      </h2>
      <div className="mb-6 flex justify-center">
        <input
          type="text"
          placeholder="Enter tracking code"
          value={trackingCode}
          onChange={(e) => setTrackingCode(e.target.value)}
          className="p-3 border border-gray-300 rounded-lg mr-2 w-full sm:w-1/2 md:w-1/3"
        />
        <button
          onClick={handleTrackOrder}
          className="px-4 py-3 bg-[#12124C] text-white rounded-lg hover:bg-[#1e1e75] duration-300"
        >
          Track
        </button>
      </div>
      {loading && <p className="text-center text-[#12124C]">Loading...</p>}
      {error && <p className="text-center text-red-500">{error}</p>}
      {orderDetails ? (
        <div className="p-6 bg-gray-100 rounded-lg">
          <h3 className="text-xl font-semibold mb-4">Order Summary</h3>
          <div className="space-y-4">
            <div>
              <p className="font-semibold">Order Code:</p>
              <p>{orderDetails.code}</p>
            </div>
            <div>
              <p className="font-semibold">Customer:</p>
              <p>{orderDetails.name}</p>
            </div>
            <div>
              <p className="font-semibold">Email:</p>
              <p>{orderDetails.email}</p>
            </div>
            <div>
              <p className="font-semibold">Shipping Address:</p>
              <p>{orderDetails.address}</p>
            </div>
            <div>
              <p className="font-semibold">Order Date:</p>
              <p>{orderDetails.created_at}</p>
            </div>
            <div>
              <p className="font-semibold">Total Order Amount:</p>
              <p>{orderDetails.grand_total}</p>
            </div>

            <div>
              <p className="font-semibold">Payment Method:</p>
              <p>{orderDetails.shipping_type}</p>
            </div>
            <div>
              <p className="font-semibold">Delivery Status:</p>
              <p className="text-green-500 font-bold">
                {orderDetails.delivery_status}
              </p>
            </div>
          </div>
        </div>
      ) : (
        !loading &&
        !error && (
          <p className="text-center text-gray-500">
            Please enter a tracking code to see the order details.
          </p>
        )
      )}
    </div>
  );
};

export default TrackOrder;
