import { useDispatch } from "react-redux";
import {
  addToSingleCart,
  addtoCart,
  removeAllFromCart,
} from "../features/products/cartSlice";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";

const FlexCard = ({ product }) => {
  // const { dataLayer } = useGTM();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const addToCartHandler = (product) => {
    dispatch(addtoCart(product, colorAtr, sizeAtr));
  };
  const [cartQuantity] = useState(1);
  const [colorAtr] = useState(null);
  const [sizeAtr] = useState(null);

  const handleBuyNow = () => {
    dispatch(removeAllFromCart());
    dispatch(addToSingleCart({ ...product, sizeAtr, colorAtr, cartQuantity }));
    navigate("/checkout");
  };
  return (
    <div className="flex flex-col border-2  md:w-[17rem]  w-[9.5rem]">
      <Link
        to={`/productdetails/${product.id}`}
        className=" w-full h-[15rem] overflow-hidden"
      >
        <img
          src={`${process.env.REACT_APP_URL}/uploads/product/${product?.photos}`}
          alt=""
          className="w-full h-full object-cover"
        />
      </Link>

      <div className="product-details bg-white flex flex-col justify-center p-2 gap-2.5">
        <span className="text-base  flex gap-2">
          <span className="font-semibold text-[#e25252] text-xl">
            <Link to={`/productdetails/${product?.id}`}>
              <div className="flex font-semibold">
                {product.unit_price === product.after_discount &&
                product.after_discount > 0 ? (
                  <div className="flex text-[16px]  justify-center">
                    ৳{product?.unit_price}
                  </div>
                ) : (
                  <div className="flex flex-row justify-between items-center text-center gap-2">
                    <span className="font-semibold text-[16px] line-through text-[#FA5303] decoration-200">
                      ৳{product?.unit_price}
                    </span>
                    <span className="text-[16px]">
                      ৳{+product?.after_discount}
                    </span>
                  </div>
                )}
              </div>
              <h3 className="text-[1.2rem] truncate  font-semibold text-[#212121] mb-2">
                {product?.name}
              </h3>
            </Link>
          </span>
        </span>
        <div className="flex flex-col text-center  gap-2 justify-center items-center text-gray-500">
          <button
            onClick={() => addToCartHandler(product)}
            className="border-[1px] border-black text-black  text-slate-50 hover:bg-[#000] hover:text-white duration-300 w-full py-1 rounded-full"
          >
            Add to Cart
          </button>

          <button
            onClick={handleBuyNow}
            className="border text-center  bg-black hover:text-slate-50 ] text-white duration-300 w-full  rounded-full py-1 "
          >
            Order Now
          </button>

          {/* to={"/productdetails/"} */}
        </div>
      </div>
    </div>
  );
};

export default FlexCard;
