import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router-dom";
import { singleVendorFatching } from "../../features/products/singleVendorSlice";
import { vendorProductFetching } from "../../features/products/vendorProductSlice";
import FlexCard from "../../components/FlexCard";
import SectionTitle from "../../components/SectionTitle";

const VendorProfile = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { items: vendor } = useSelector((state) => state.vendorSingleInfo);

  const { items: vendorProduct } = useSelector((state) => state.vendorProduct);

  useEffect(() => {
    dispatch(singleVendorFatching(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(vendorProductFetching(id));
  }, [dispatch, id]);
  return (
    <div className="container mx-auto flex flex-col gap-5">
      {vendor &&
        vendor?.user?.map((vendorData) => (
          <div
            key={vendorData.id}
            className="vendor-details border-b border-[#ddd] py-2 flex justify-center gap-5 items-center  mt-5"
          >
            <div className="user-image w-20 h-20 rounded-full overflow-hidden">
              {vendorData?.avatar ? (
                <img
                  src={`${process.env.REACT_APP_URL}/uploads/users/${vendorData?.avatar}`}
                  alt={`${vendorData?.name}`}
                  className="w-full h-full object-cover"
                />
              ) : (
                <img
                  src="/default_image.jpeg"
                  alt="user"
                  className="w-full h-full object-cover rounded-full"
                />
              )}
            </div>
            <div className="flex flex-col justify-center">
              <h3 className="text-[20px] md:text-[22px] capitalize font-semibold ">
                {vendorData?.name}
              </h3>
              <p className="text-xs text-[#333]">
                {vendorData?.address && vendorData?.address}
              </p>
            </div>
          </div>
        ))}
      <div className="vendor-product p-2 mb-5">
        {/* <h2 className="text-3xl font-semibold mb-5">Products</h2> */}
        <SectionTitle title={"Products"} />
        <div className="flex flex-wrap gap-5 justify-center">
          {vendorProduct?.vendor_product &&
            vendorProduct?.vendor_product?.map((product) => (
              <FlexCard product={product} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default VendorProfile;
