import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Autoplay, HashNavigation, Navigation } from "swiper";
import { useSelector } from "react-redux";
// import { useState } from "react";
// import {
//   MdOutlineKeyboardArrowDown,
//   MdOutlineKeyboardArrowUp,
// } from "react-icons/md";

import BannerSub from "../components/BannerSub";

const BannerSlider = () => {
  const { items: data } = useSelector((state) => state.banners);
  const { items: subcategories } = useSelector((state) => state.subCategor);

  const getSubcategoriesByCategoryId = (categoryId) => {
    return subcategories?.filter(
      (subcategory) => +subcategory?.category_id === categoryId
    );
  };

  return (
    <div className="container mx-auto  mt-5 ">
      <div className="grid md:grid-cols-[25%_75%] grid-cols-1 gap-3">
        <div className="bg-white hidden md:block">
          <BannerSub
            getSubcategoriesByCategoryId={getSubcategoriesByCategoryId}
          />
        </div>
        <div className="banner-slider-frame 2xl:h-[28rem]">
          <Swiper
            slidesPerView={"auto"}
            spaceBetween={30}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            speed={700}
            hashNavigation
            loop={true}
            modules={[Autoplay, Navigation, HashNavigation]}
            navigation={true}
            className="mySwiper h-full"
          >
            {data.map((image) => (
              <div key={image.id}>
                <SwiperSlide>
                  <a
                    href={`${image.url}`}
                    target="_blank"
                    rel="noreferrer"
                    className="w-full  md:h-[21rem] h-[12rem] 2xl:h-[22rem] overflow-hidden"
                  >
                    <img
                      key={image.id}
                      src={`${process.env.REACT_APP_URL}/public/uploads/slider/${image?.slider}`}
                      alt={image.alt}
                      className={` h-full  w-full  transition-opacity duration-500 object-cover `}
                    />
                  </a>
                </SwiperSlide>
              </div>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default BannerSlider;
