import { FaFacebook } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";

const Footer = () => {
  const { pages: footerPages } = useSelector((state) => state.footerPages);

  const { items: logo } = useSelector((state) => state.logo);

  const { items: adminInfo } = useSelector((state) => state.information);

  const navActive = ({ isActive }) => {
    return {
      color: isActive ? "#06b6d4" : null,
      font: isActive ? "bold" : null,
    };
  };
  return (
    // <div className="  text-slate-50 md:py-8 mb-5 md:mb-0  text-black">
    //   <div className="  grid lg:grid-cols-5">
    //     <div className="lg:col-span-2">
    //       <span className="font-light gap-3 flex flex-col   2xl:flex-row lg:flex-col  justify-between  text-sm">
    //         <span className="flex flex-col mb-5">
    //           {/* <h4 className="mb-2">OFF Track Online</h4> */}
    //           <h2 className="mb-2 text-base bg-[#15144E] text-[#e7e4e2]">
    //             Bazar Map
    //           </h2>
    //           <p className=" container  mx-auto w-[90%]">
    //             Bazar Map is your essential guide to exploring markets, bazaars,
    //             and local shops across Bangladesh. Discover unique treasures,
    //             compare prices, and enjoy authentic shopping experiences with
    //             our comprehensive marketplace directory.
    //           </p>
    //         </span>
    //       </span>
    //       <div className="container mx-auto">
    //         <div className=" brand  h-[4rem] w-[8rem]  rounded-md overflow-hidden">
    //           <img
    //             src={`${process.env.REACT_APP_URL}/backend/assets/jpg/${logo?.avatar}`}
    //             className="w-full h-full mb-5"
    //             alt=""
    //           />
    //         </div>
    // <div className="flex  items-center gap-4">
    //   <h2 className="text-md font-light">Follow Us</h2>
    //   <div className="flex items-center">
    //     {/* <Link
    //     href={`https://www.facebook.com/NikoPetmart?mibextid=ZbWKwL`}
    //     target="_blank"
    //     rel="noopener noreferrer"
    //     className="text-white hover:text-blue-400 mr-4"
    //   >
    //     <FaFacebook />
    //   </Link> */}
    //     <a
    //       href="#0"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //       className="text-white hover:text-blue-400 mr-4"
    //     >
    //       <FaFacebook />
    //     </a>
    //     {/* <a
    //     href="https://www.tiktok.com/@elano.store?_t=8kKsMLxAhs1&_r=1"
    //     target="_blank"
    //     rel="noopener noreferrer"
    //     className="text-white hover:hover:text-white mr-4"
    //   >
    //     <FaTiktok />
    //   </a> */}
    //     {/* <a
    //     href="https://www.instagram.com/insta_elano.store?igsh=OW04cjdlbWEzdW5h"
    //     target="_blank"
    //     rel="noopener noreferrer"
    //     className="text-white hover:text-red-400"
    //   >
    //     <FaInstagram />
    //   </a> */}
    //   </div>
    // </div>
    //       </div>
    //     </div>

    //     <div className="flex flex-col ">
    //       <h2 className="text-base mb-2 text-[#e7e4e2] bg-[#15144E]  ">
    //         Useful Links
    //       </h2>
    //       <NavLink
    //         style={navActive}
    //         end
    //         to={"/track-order"}
    //         className="hover:text-gray-300 duration-300"
    //       >
    //         Track Order
    //       </NavLink>
    //       <NavLink
    //         style={navActive}
    //         end
    //         to={"/register"}
    //         className="hover:text-gray-300 duration-300"
    //       >
    //         Become a Seller
    //       </NavLink>
    //     </div>
    //     <div className="flex flex-col ">
    //       <h2 className="text-base mb-2 text-[#e7e4e2] bg-[#15144E]  ">
    //         Customer Care
    //       </h2>

    //       {footerPages?.map((pages) => (
    //         <Link
    //           key={pages.id}
    //           to={`/pages/${pages.slug}`}
    //           className=" inline-block text-sm font-light text-slat NavLink
    //         "
    //         >
    //           {pages.title}
    //         </Link>
    //       ))}
    //     </div>
    // <div className="flex flex-col ">
    //   <h2 className="text-base mb-2 text-[#e7e4e2] bg-[#15144E]  ">
    //     Contact info
    //   </h2>
    //   <Link className=" inline-block text-sm font-light text-slate-50 ">
    //     {adminInfo.address}
    //   </Link>

    //   <Link className=" inline-block text-sm font-light text-slate-50">
    //     Phone:{adminInfo.phone}
    //   </Link>
    //   <Link
    //     className=" inline-block text-sm font-light text-slat NavLink
    //     "
    //   >s
    //     Email: {adminInfo.email}
    //   </Link>
    // </div>
    //   </div>

    //   <footer className="footer container mx-auto flex items-center justify-between    gap-5  text-slate-50 ">
    //     <div className="flex justify-center">
    //       <p className="text-slate-50 text-sm font-light mt-5 capitalize">
    //         &copy; {new Date().getFullYear()}{" "}
    //         {adminInfo?.email?.split("@")[1].split(".")[0]}. All rights
    //         reserved.
    //       </p>
    //     </div>
    //     {/* <div className="image-container w-72 2xl:w-[30rem] ">
    //       <img
    //         src="https://www.old.mehendibymimi.com/public/uploads/all/lnfIO3PdB1OE45OgRLmHu8fZdLRselIYRdKpAyam.png"
    //         alt=""
    //       />
    //     </div> */}
    //   </footer>
    // </div>
    <div className="footer">
      <div className="bg-[#15144E] hidden lg:block ">
        <div className="footer-menu  grid grid-cols-5 gap-4 container mx-auto py-2 text-[#e7e4e2]">
          <h2 className="md:col-span-2">Bazar Map</h2>
          <h2>Useful Links</h2>
          <h2>Customer Care</h2>
          <h2>Contact info</h2>
        </div>
      </div>

      <div className="bg-white md:p-0 p-2 mb-5 md:mb-0">
        <div className="footer-content container mx-auto grid lg:grid-cols-5 gap-4 py-4 ">
          <div className="website-description lg:col-span-2 ">
            <p className="">
              Bazar Map is your essential guide to exploring markets, bazaars,
              and local shops across Bangladesh. Discover unique treasures,
              compare prices, and enjoy authentic shopping experiences with our
              comprehensive marketplace directory.
            </p>
            <div className=" brand  h-[4rem] w-[8rem]  rounded-md overflow-hidden">
              <img
                src={`${process.env.REACT_APP_URL}/backend/assets/jpg/${logo?.avatar}`}
                className="w-full h-full mb-5"
                alt=""
              />
            </div>
            <div className="flex  items-center gap-4">
              <h2 className="text-sm">Follow Us</h2>
              <div className="flex items-center">
                <a
                  href="#0"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-700 hover:text-blue-400 mr-4"
                >
                  <FaFacebook />
                </a>
                {/* <a
                href="https://www.tiktok.com/@elano.store?_t=8kKsMLxAhs1&_r=1"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:hover:text-white mr-4"
              >
                <FaTiktok />
              </a> */}
                {/* <a
                href="https://www.instagram.com/insta_elano.store?igsh=OW04cjdlbWEzdW5h"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-red-400"
              >
                <FaInstagram />
              </a> */}
              </div>
            </div>
          </div>
          <div className="flex flex-col ">
            <h2 className="text-base lg:hidden py-2 px-1  mb-2 text-[#e7e4e2] bg-[#15144E]  ">
              Useful Links
            </h2>
            <NavLink
              style={navActive}
              end
              to={"/track-order"}
              className="hover:text-gray-300 duration-300"
            >
              Track Order
            </NavLink>
            <NavLink
              style={navActive}
              end
              to={"/register"}
              className="hover:text-gray-300 duration-300"
            >
              Become a Seller
            </NavLink>
          </div>
          <div className="flex flex-col ">
            <h2 className="text-base lg:hidden py-2 px-1  mb-2 text-[#e7e4e2] bg-[#15144E]  ">
              Customer Care
            </h2>
            {footerPages?.map((pages) => (
              <Link
                key={pages.id}
                to={`/pages/${pages.slug}`}
                className=" inline-block  NavLink
            "
              >
                {pages.title}
              </Link>
            ))}
          </div>
          <div className="flex flex-col ">
            <h2 className="text-base lg:hidden mb-2 py-2 px-1 text-[#e7e4e2] bg-[#15144E]  ">
              Contact info
            </h2>
            <Link className=" inline-block  ">{adminInfo.address}</Link>

            <Link className=" inline-block  ">Phone:{adminInfo.phone}</Link>
            <Link
              className=" inline-block NavLink
            "
            >
              Email: {adminInfo.email}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
