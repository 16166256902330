import axios from "axios";
import React, { useState } from "react";
import { useSelector } from "react-redux";

const VendorPayout = () => {
  const { userAndToken: vendorData } = useSelector((state) => state.auth);

  const [formData, setFormData] = useState({
    user_email: vendorData?.user?.email || "",
    amount: "",
    status: "pending",
  });

  const [image, setImage] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSingleImage = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = new FormData();
    payload.append("user_email", formData.user_email);
    payload.append("amount", formData.amount);
    payload.append("status", formData.status);
    if (image) {
      payload.append("proof", image);
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_URL}/api/payout/request`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${vendorData?.access_token}`,
          },
        }
      );

      setFormData({
        user_email: vendorData?.user?.email || "",
        amount: "",
        status: "pending",
      });
      setImage(null);

      alert("Apply successfully!");
    } catch (error) {
      console.error("There was an error for applying:", error);
      alert("Failed to apply. Please try again.");
    }
  };

  return (
    <div className="max-w-md mx-auto mt-10">
      <form
        className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
        onSubmit={handleSubmit}
      >
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="user_email"
          >
            Email
          </label>
          <input
            id="user_email"
            name="user_email"
            type="email"
            placeholder="Enter your email"
            value={formData.user_email}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="amount"
          >
            Amount
          </label>
          <input
            id="amount"
            name="amount"
            type="number"
            placeholder="Enter amount"
            value={formData.amount}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="status"
          >
            Status
          </label>
          <select
            id="status"
            name="status"
            value={formData.status}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          >
            <option value="pending">Pending</option>
            <option value="hold">Hold</option>
            <option value="paid">Paid</option>
          </select>
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="proof"
          >
            Proof
          </label>
          <input
            id="proof"
            name="proof"
            type="file"
            accept="image/*"
            onChange={handleSingleImage}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="bg-[#12124C] hover:bg-[#21218f] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default VendorPayout;
